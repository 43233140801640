import React from 'react'
import { graphql } from 'gatsby'
import type { PageProps } from 'gatsby'
import { Redirect } from '@reach/router'
import { NpmCouponsPage } from '@plurix/ecom-pages'

interface FeatureToggleSection {
  cmsFeatureToggle?: {
    sections?: Array<{
      data?: { [key: string]: boolean }
    }>
  }
}

const Coupons = ({ data }: PageProps<FeatureToggleSection>) => {
  const hasCoupons = data?.cmsFeatureToggle?.sections?.[0]?.data?.hasCoupons

  // redirect to 404
  if (!hasCoupons) {
    const params = new URLSearchParams({
      from: encodeURIComponent('/cupons-de-oferta'),
    })

    return <Redirect to={`/404?${params.toString()}`} noThrow />
  }

  return <NpmCouponsPage />
}

export const querySSG = graphql`
  query CouponsPageQuery {
    cmsFeatureToggle {
      sections {
        data
      }
    }
  }
`

export default Coupons
